/* Global Styles */
:root {
	--primary-color: #17a2b8;
	--dark-color: #343a40;
	--light-color: #f4f4f4;
	--danger-color: #dc3545;
	--success-color: #28a745;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Raleway', sans-serif;
	font-size: 1rem;
	line-height: 1.6;
	background-color: #fff;
	color: #333;
}

a {
	color: var(--primary-color);
	text-decoration: none;
}

ul {
	list-style: none;
}

img {
	width: 100%;
}

ins { 
	color: green;
	text-decoration: none;
	background-color: rgba(46,160,67,0.4);
}
del {
	color: red;
	text-decoration: none;
	background-color: rgba(248,81,73,0.4);
}

/* Utilities */
.container {
	max-width: 70% !important;
	overflow: hidden;
	padding: 0 2rem;
	margin-top: 6rem;
	margin-bottom: 3rem;
}

/* Text Styles*/
.x-large {
	font-size: 4rem;
	line-height: 1.2;
	margin-bottom: 1rem;
}

.large {
	font-size: 3rem;
	line-height: 1.2;
	margin-bottom: 1rem;
}

.medium {
	font-size: 2rem;
	line-height: 1.2;
	margin-bottom: 1rem;
}

.lead {
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

.text-center {
	text-align: center;
}

.text-primary {
	color: var(--primary-color);
}

.text-dark {
	color: var(--dark-color);
}

/* Padding */
.p {
	padding: 0.5rem;
}

.p-1 {
	padding: 1rem;
}

.p-2 {
	padding: 2rem;
}

.p-3 {
	padding: 3rem;
}

.py {
	padding: 0.5rem 0;
}

.py-1 {
	padding: 1rem 0;
}

.py-2 {
	padding: 2rem 0;
}

.py-3 {
	padding: 3rem 0;
}

/* Margin */
.m {
	margin: 0.5rem;
}

.m-1 {
	margin: 1rem;
}

.m-2 {
	margin: 2rem;
}

.m-3 {
	margin: 3rem;
}

.my {
	margin: 0.5rem 0;
}

.my-1 {
	margin: 1rem 0;
}

.my-2 {
	margin: 2rem 0;
}

.my-3 {
	margin: 3rem 0;
}

.btn {
	display: inline-block;
	background: var(--light-color);
	color: #333;
	padding: 0.4rem 1.3rem;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	margin-right: 0.5rem;
	transition: opacity 0.2s ease-in;
	outline: none;
}

.badge {
	font-size: 0.8rem;
	padding: 0.1rem;
	text-align: center;
	margin: 0.3rem;
	background: var(--light-color);
	color: #333;
}

.alert {
	padding: 0.8rem;
	margin: 1rem 0;
	opacity: 0.9;
	background: var(--light-color);
	color: #333;
}

.alert-login {
	margin-left: 42%;
	width: 54%;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
	background: var(--primary-color);
	color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
	background: var(--light-color);
	color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
	background: var(--dark-color);
	color: #fff;
}

.navbar-bg {
	background: rgb(58, 100, 249);
	color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
	background: var(--danger-color);
	color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
	background: var(--success-color);
	color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
	background: #fff;
	color: #333;
	border: #ccc solid 1px;
}

.btn:hover {
	opacity: 0.8;
}

.bg-light,
.badge-light {
	border: #ccc solid 1px;
}

.round-img {
	border-radius: 50%;
}

.line {
	height: 1px;
	background: #ccc;
	margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Forms */
.form .form-group {
	margin: 1.2rem 0;
}

.form .form-text {
	display: block;
	margin-top: 0.3rem;
	color: #888;
}

.custom-form{
	margin: 1.2rem 0;
	margin-top: 0.3rem;
	color: #888;
}

.custom-form input[type='text'],
.custom-form input[type='email'],
.custom-form input[type='password'],
.custom-form input[type='date'],
.custom-form select,
.custom-form textarea {
	padding: 0.4rem;
	font-size: 1.2rem;
	border: 1px solid #ccc;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
	display: block;
	width: 100%;
	padding: 0.4rem;
	font-size: 1.2rem;
	border: 1px solid #ccc;
}

.form input[type='submit'],
button {
	font: inherit;
}

.form .social-input {
	display: flex;
}

.form .social-input i {
	padding: 0.5rem;
	width: 4rem;
}

.form .social-input i.fa-twitter {
	color: #38a1f3;
}

.form .social-input i.fa-facebook {
	color: #3b5998;
}

.form .social-input i.fa-instagram {
	color: #3f729b;
}

.form .social-input i.fa-youtube {
	color: #c4302b;
}

.form .social-input i.fa-linkedin {
	color: #0077b5;
}

.table th,
.table td {
	padding: 1rem;
	text-align: left;
}

.table th {
	background: var(--light-color);
}

/* Navbar */
.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.7rem 2rem;
	position: fixed;
	z-index: 1;
	width: 100%;
	top: 0;
	border-bottom: solid 1px var(--primary-color);
	opacity: 0.9;
}

.navbar ul {
	display: flex;
}

.navbar a {
	color: #fff;
	padding: 0.5rem;
	margin: 0 0.5rem;
}

.navbar a:hover {
	color: var(--primary-color);
}

.navbar .welcome span {
	margin-right: 0.6rem;
}

/* Landing Page */
.landing {
	position: relative;
	height: 100vh;
}

.landing-inner {
	color: black !important;
	height: 100%;
	width: 80%;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

/* Profiles Page */
.profile {
	display: grid;
	grid-template-columns: 2fr 4fr 2fr;
	align-items: center;
	grid-gap: 2rem;
	padding: 1rem;
	line-height: 1.8;
	margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
	display: grid;
	grid-template-areas:
		'top top'
		'about about'
		'exp edu'
		'github github';
	grid-gap: 1rem;
}

.profile-top {
	grid-area: top;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.profile-top img {
	width: 250px;
}

.profile-top .icons a {
	color: #fff;
	margin: 0 0.3rem;
}

.profile-top .icons a:hover {
	color: var(--dark-color);
}

.profile-about {
	grid-area: about;
	text-align: center;
}

.profile-about .skills {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.profile-exp {
	grid-area: exp;
}

.profile-edu {
	grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
	margin-bottom: 1rem;
}

.profile-exp>div,
.profile-edu>div {
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: #ccc 1px dotted;
}

.profile-exp>div:last-child,
.profile-edu>div:last-child {
	border: 0;
}

.profile-exp p,
.profile-edu p {
	margin: 0.5rem 0;
}

.profile-github {
	grid-area: github;
}

.profile-github .repo {
	display: flex;
}

.profile-github .repo>div:first-child {
	flex: 7;
	flex-basis: 70%;
}

.profile-github>div:last-child {
	flex: 3;
	flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
	background: var(--primary-color);
	padding: 0.5rem;
}

.post {
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-gap: 2rem;
	align-items: center;
}

.post>div:first-child {
	text-align: center;
}

.post img {
	width: 100px;
}

.post .comment-count {
	background: var(--light-color);
	color: var(--primary-color);
	padding: 0.1rem 0.2rem;
	border-radius: 5px;
	font-size: 0.8rem;
}

.post .post-date {
	color: #aaa;
	font-size: 0.8rem;
	margin-bottom: 0.5rem;
}

/* Mobile Styles */
@media (max-width: 700px) {
	.container {
		margin-top: 8rem;
	}

	.hide-sm {
		display: none;
	}

	/* Text Styles */
	.x-large {
		font-size: 3rem;
	}

	.large {
		font-size: 2rem;
	}

	.lead {
		font-size: 1rem;
	}

	/* Navbar */
	.navbar {
		display: block;
		text-align: center;
	}

	.navbar ul {
		text-align: center;
		justify-content: center;
		margin-top: 1rem;
	}

	.navbar h1 {
		margin-bottom: 1rem;
	}

	.navbar .welcome {
		display: none;
	}

	/* Profiles Page */
	.profile {
		grid-template-columns: 1fr;
		text-align: center;
	}

	.profile ul {
		display: none;
	}

	/* Profile Page */

	.profile-top img,
	.profile img {
		width: 200px;
		margin: auto;
	}

	.profile-grid {
		grid-template-areas:
			'top'
			'about'
			'exp'
			'edu'
			'github';
	}

	.profile-about .skills {
		flex-direction: column;
	}

	.dash-buttons a {
		display: block;
		width: 100%;
		margin-bottom: 0.2rem;
	}

	.post {
		grid-template-columns: 1fr;
	}

	.post a,
	.post button {
		padding: 0.3rem 0.4rem;
	}
}

.row {
	display: flex;
}

.column {
	flex: 50%;
}

.dashboard-card {
	margin: 0 2%;
	/* padding: 5px; */
	border: solid black 1px;
	border-radius: 25px;
	text-align: center;
	width: 45%;
}

.dashboard-card-title {
	padding: 0.5rem 1rem;
	color: steelblue;
}

.dashboard-card-icon {
	color: steelblue;
	padding: 1rem 0px 2.5rem 0px;
}

.dashboard-card:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.form-card {
	margin: 10px;
	padding: 10px;
	border-bottom: solid black 1px;
	text-align: center;
	width: 100%;
	color: black
}

.form-name {
	text-align: left;
	padding-left: 5%;
}

.text-left-right {
	text-align: right;
	position: relative;
}

.left-text {
	left: -0.5%;
	white-space: nowrap;
	max-width: 80%;
	overflow: hidden;
	text-align: left;
	position: absolute;
}

.byline {
	font-size: 16px;
	color: rgba(140, 140, 140, 1);
}

.byline-error {
	font-size: 16px;
	color: red;
}

.byline-success {
	font-size: 16px;
	color: green;
}

.logout-text {
	padding-top: 2.5%;
}

.part-subheader {
	font-weight: bold;
	padding-right: 3%;
}

.form-toolbar {
	padding: 0% 2% 3% 1%;
}

.link {
	text-decoration: none !important;
}

.link-form-card{
	color:black;
}

.link-icon{
	color: rgb(58, 100, 249);
}

.link-icon-support-text:hover{
	text-decoration: underline;}

.product-detail-header {
	padding: 0 10em 2em 10em;
}

.productDetailButton {
	min-width: 20em;
}

.add-product-icon {
	padding-top: 1%;
	padding-right: 2%;
}

.dd-wrapper {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
	width: 35%;
	outline: none !important;
}

.dd-header {
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	line-height: 38px;
	border: 1px solid #dfdfdf;
	border-radius: 3px;
	cursor: default;
	position: relative;
	background-color: #fff;
}

.dd-header span {
	margin-right: 20px;
}

.dd-header-title {
	font-weight: 300;
	margin: 2px 20px;
	margin-right: 30px;
}

.dd-list {
	position: absolute;
	z-index: 10;
	width: 100%;
	border: 1px solid #dfdfdf;
	border-top: none;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
	background-color: #fff;
	-webkit-box-shadow: 0 2px 5px -1px #e8e8e8;
	box-shadow: 0 2px 5px -1px #e8e8e8;
	/* font-weight: 100; */
	padding: 15px 0;
	max-height: 215px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

.dd-list-item {
	width: 100%;
	padding: 8px 10px;
	line-height: 1.6rem;
	cursor: default;
	display: inline-block;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.dd-list-item.selected {
	color: #fff;
	background-color: #ffcc01;
}

.dd-list-item:hover {
	color: #fff;
	background-color: #ffcc01;
}

.product-detail-product {
	padding-left: 1%;
	width: 60%;
}

.form-submit-button {
	margin-top: 5%;
}

.inactive-ingredient-header {
	padding-left: 1.5%;
	display: flex;
}

.add-inactive-ingredient-icon {
	margin-left: 1%;
}

.inactive-ingredient-container {
	/* padding: 1.5% 0 1.5% 0; */
}

.login-main-text {
	margin-top: 20%;
	padding: 60px;
	color: #fff;
}

.login-main-text h2 {
	font-weight: 500;
}

@media screen and (min-width: 768px) {
	.main {
		margin-left: 40%;
	}

	.sidenav {
		width: 40%;
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
	}

	.login-form {
		margin-top: 80%;
	}

	.register-form {
		margin-top: 20%;
	}
}

body {
	font-family: "Lato", sans-serif;
}

.main-head {
	height: 150px;
	background: #FFF;

}

.sidenav {
	height: 100%;
	background-color: rgb(58, 100, 249);
	overflow-x: hidden;
}


.main {
	padding: 0px 10px;
}

@media screen and (max-height: 450px) {
	.sidenav {
		padding-top: 15px;
	}
}

@media screen and (max-width: 450px) {
	.login-form {
		margin-top: 10%;
	}

	.register-form {
		margin-top: 10%;
	}
}

.login-field {
	width: 200%
}

.login-button {
	min-width: 200%
}

.login-back {
	color: white;
}


/* Styling for not found content table */
.not-found-table {
	width: 45%;
	margin-top: 5%;
	margin-left: 30%;
}

/* Not Found page table's text column styling */
.not-found-text-column {
	width: 10%;
}

/* Not Found page's text styling */
.not-found-text {
	padding-top: 3%;
	text-align: left;
}

/* Not Found page table's image column styling */
.not-found-image-column {
	text-align: center;
	width: 10%;
}

/* .editor {
	padding: 3% 0% 3% 2%;
} */

.sub-editor {
	padding: 3% 0% 0% 3%;
}

.sub-sub-editor {
	padding: 3% 0% 3% 6%;
}

.optional-section {
	padding-left: 1.5%;
}

.row-right{
	position: absolute;
	right: 22%;	
	margin-top: -3%;
}

.tox-notifications-container{
	display: none;
}

.page-footer{
	background-color: rgb(58, 100, 249);
	color: white;
}

.page-footer a{
	color: white;
}

.page-footer a:hover{
	color:black;
}

.filter{
	margin-left: -1%;
	width: 120%;
}

.filter-label{
	color: black;
	padding-top: 0.7%;
	padding-left: 1.5%;
	padding-right: 0.5%
}

.pagination{
	margin: auto;
	padding: 10px;
}

th, td {
	padding: 15px;
}

.archive-diff{
	border: 2px solid red;
}

.archive-original{
	border: 2px solid yellow;

}

.nav-ul {
	margin-top: 1rem;
}

.tox {
	border-radius: 25px !important;
}

.OTP-inputStyle {
	width: 6rem !important;
	height: 6rem;
	margin: 0 auto;
	font-size: 3rem;
	border-radius: 25px;
	border: 1px solid rgba(0, 0, 0, 0.3);
}

.OTP-inputStyleReduced {
	width: 4rem !important;
	height: 4rem;
	font-size: 2rem;
	border-radius: 15px;
	border: 1px solid rgba(0, 0, 0, 0.3);
}
